import React from 'react';
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import { AuthContextProvider } from "./context/AuthContext";
// Soft UI Context Provider
import { SoftUIControllerProvider } from "context";


// Utilisation de createRoot (React 18+)
const root = ReactDOM.createRoot(
  document.getElementById('root')
);


root.render(
  <BrowserRouter>
    <AuthContextProvider>
      <SoftUIControllerProvider>
          <App />
      </SoftUIControllerProvider>
    </AuthContextProvider>
  </BrowserRouter>
);