/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.3
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { auth } from "config/firebase";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Switch from "@mui/material/Switch";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";

// Authentication layout components
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";

// Image
import chat from "assets/images/illustrations/chat.png";

function SignIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const googleProvider = new GoogleAuthProvider();

  const handleGoogleSignIn = async () => {
    try {
      await signInWithPopup(auth, googleProvider);
    } catch (err) {
      setError("Erreur lors de la connexion avec Google");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (err) {
      setError("Identifiants invalides");
    }
  };

  return (
    <IllustrationLayout
      title="Connexion"
      description="Entrez vos identifiants pour vous connecter"
      illustration={{
        image: chat,
        title: "Bienvenue",
        description: "Connectez-vous pour accéder à votre espace",
      }}
    >
      <SoftBox component="form" role="form" onSubmit={handleSubmit}>
        {error && (
          <SoftBox mb={2}>
            <SoftTypography variant="button" color="error">
              {error}
            </SoftTypography>
          </SoftBox>
        )}
        <SoftBox mb={2}>
          <SoftInput
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </SoftBox>
        <SoftBox mb={2}>
          <SoftInput
            type="password"
            placeholder="Mot de passe"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </SoftBox>
        <SoftBox mt={4} mb={1}>
          <SoftButton type="submit" variant="gradient" color="info" fullWidth>
            Se connecter
          </SoftButton>
        </SoftBox>

        <SoftBox mt={3}>
          <SoftButton
            variant="outlined"
            color="secondary"
            fullWidth
            onClick={handleGoogleSignIn}
            startIcon={
              <img
                src="https://www.google.com/favicon.ico"
                alt="google"
                style={{ width: "20px", height: "20px", marginRight: "8px" }}
              />
            }
          >
            Continuer avec Google
          </SoftButton>
        </SoftBox>
      </SoftBox>
    </IllustrationLayout>
  );
}

export default SignIn;
