/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.3
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from './config/firebase';
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Sidenav from "./components/Sidenav";
import theme from "./assets/theme";
import routes from "./routes";
import SignIn from "./layouts/authentication/sign-in/illustration";
import AuthGuard from "./components/AuthGuard";

// Soft UI Dashboard PRO React contexts
import {
  useSoftUIController,
  setTransparentSidenav,
  setMiniSidenav,
  setFixedNavbar,
  setSidenavColor,
} from "./context";

// Images
import brand from "./assets/images/logo_web.png";

function App() {
  const [user, loading] = useAuthState(auth);
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, direction, layout, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    setTransparentSidenav(dispatch, true);
    setMiniSidenav(dispatch, false);
    setFixedNavbar(dispatch, true);
    setSidenavColor(dispatch, "info");
  }, [dispatch]);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };


  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  if (loading) {
    return <div>Loading...</div>;
  }

  const getRoutePath = (path) => {
    if (!path) return "";
    return path.startsWith("/") ? path.slice(1) : path;
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {layout === "dashboard" && (
        <Sidenav
          color={sidenavColor}
          brand={brand}
          brandName="Soft UI Dashboard PRO"
          routes={routes}
          onMouseEnter={handleOnMouseEnter}
          onMouseLeave={handleOnMouseLeave}
        />
      )}
      <Routes>
        {/* Route de connexion */}
        <Route 
          path="/login" 
          element={user ? <Navigate to="/dashboards/default" /> : <SignIn />} 
        />

        {/* Routes protégées */}
        <Route
          path="/*"
          element={
            <AuthGuard>
                <Routes>
                  {routes.map((route) => {
                    if (route.route) {
                      return (
                        <Route
                          key={route.key}
                          path={getRoutePath(route.route)}
                          element={route.component}
                        />
                      );
                    }
                    if (route.collapse) {
                      return route.collapse.map((collapseRoute) => {
                        if (collapseRoute.collapse) {
                          return collapseRoute.collapse.map((nestedRoute) => (
                            <Route
                              key={nestedRoute.key}
                              path={getRoutePath(nestedRoute.route)}
                              element={nestedRoute.component}
                            />
                          ));
                        }
                        return (
                          <Route
                            key={collapseRoute.key}
                            path={getRoutePath(collapseRoute.route)}
                            element={collapseRoute.component}
                          />
                        );
                      });
                    }
                    return null;
                  })}
                  <Route path="dashboard" element={<Navigate to="/dashboards/default" />} />
                  <Route path="*" element={<Navigate to="/dashboards/default" />} />
                </Routes>
            </AuthGuard>
          }
        />

        {/* Redirection par défaut */}
        <Route 
          path="/" 
          element={<Navigate to={user ? "/dashboards/default" : "/login"} />} 
        />
      </Routes>
    </ThemeProvider>
  );
}

export default App;
