import { useAuthState } from 'react-firebase-hooks/auth';
import { Navigate } from 'react-router-dom';
import { auth } from '../config/firebase';
import SoftBox from "components/SoftBox";

function AuthGuard({ children }) {
  const [user, loading] = useAuthState(auth);

  if (loading) {
    return (
      <SoftBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <div>Chargement...</div>
      </SoftBox>
    );
  }

  if (!user) {
    return <Navigate to="/login" replace />;
  }

  return children;
}

export default AuthGuard;